import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  Alert,
  AlertProps,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import { TicketCard } from 'components'
import CancelOrderButton from 'components/CancelOrderButton'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { functionWithLoading } from 'utils'
import { moneyFormatter, normalizeDateTime } from 'utils/formatter'

const OrderDetailsPage: React.FC = () => {
  const { orderId } = useParams()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<IPedido>()

  const load = useCallback(() => {
    if (orderId)
      functionWithLoading(
        apiV1.orderService
          .getOrder(orderId ?? '')
          .then((res) => setOrder(res.data)),
        setLoading,
      )
  }, [orderId])

  useEffect(() => {
    load()
  }, [load])

  if (loading)
    return (
      <Container maxWidth="md">
        <Skeleton height={400} />
      </Container>
    )

  if (!order)
    return (
      <Container maxWidth="md">
        <Typography variant="h6" gutterBottom>
          Ocorreu um erro ao carregar o pedido, tente novamente por favor. Se
          caso continuar com erro entre em contato com o suporte.
        </Typography>
      </Container>
    )

  let statusColor = 'black'
  let renderTickets = false
  const alertProps: AlertProps = { severity: 'info' }

  switch (order.cod_status) {
    case 3:
    case 4:
    case 100:
    case 101:
    case 404:
      statusColor = 'green'
      renderTickets = true
      alertProps.severity = 'success'
      break
    case 107:
    case 7:
      statusColor = 'red'
      alertProps.severity = 'error'
      break
    case 6:
      statusColor = 'orange'
      alertProps.severity = 'warning'
      break
    default:
      break
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        component={Paper}
        sx={{ my: 2, pr: 2, pb: 2 }}
        spacing={2}
      >
        <Grid item xs={12} sm={8}>
          <Typography variant="h6">COMPRADOR</Typography>
          <Typography variant="subtitle2">
            {[order.usuario.nome, order.usuario.sobrenome].join(' ')}
          </Typography>
          <Typography variant="subtitle2">{order.usuario.email}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            EVENTO
          </Typography>
          <Typography
            variant="h6"
            component={Link}
            to={`/evento/${order.evento.id}/${order.evento.id_url}`}
          >
            {order.evento.titulo}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <CalendarMonthIcon />
            {moment(order.evento.data).add(3, 'hours').format('LLLL')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <LocationOnIcon />{' '}
            {order.evento?.local?.nome ??
              `${order.evento.local_evento} - ${order.evento.brasil_cidade?.cidade} - ${order.evento.brasil_cidade?.uf}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Alert {...alertProps} sx={{ justifyContent: 'center' }} icon={false}>
            <Typography variant="subtitle2" textAlign="center">
              Pedido
            </Typography>
            <Typography variant="h6" textAlign="center">
              {order.codigo}
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              {normalizeDateTime(order.data).format('L HH:MM:SS')}
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              sx={{ color: statusColor }}
            >
              {order.status}
              {order.cod_status === 108 && (
                <Typography variant="body2" fontSize={12} textAlign="justify">
                  O Zé do ingresso não se responsabiliza por ingressos comprados
                  e transferidos de terceiros, pois os mesmos podem ser
                  cancelados caso o comprador original não cumpra os termos do
                  site, conteste o pagamento ou dentre outras situações. Não
                  haverá qualquer reposição do ingresso, devendo o consumidor,
                  caso queira, efetuar outra compra em pontos oficiais.
                </Typography>
              )}
            </Typography>
            <Typography variant="h6" textAlign="center">
              {moneyFormatter.format(order.valor_pedido)}
            </Typography>
            {order.isCancelable && <CancelOrderButton orderId={order.codigo} />}
          </Alert>
        </Grid>

        {renderTickets ? (
          <Grid item xs={12}>
            <Typography variant="h6">MEUS INGRESSOS</Typography>
            <Grid container spacing={2}>
              {order.pedido_item?.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <TicketCard item={item} event={order.evento} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <List disablePadding>
                {order.pedido_item?.map((item, index) => (
                  <ListItem key={`${item.id}_${index}`} sx={{ py: 1, px: 0 }}>
                    <ListItemText
                      primary={item.ingresso.titulo}
                      secondary={`taxa de serviço`}
                    />
                    <ListItemText
                      primary={moneyFormatter.format(item.valor_ingresso)}
                      secondary={moneyFormatter.format(item.valor_taxa)}
                      sx={{ textAlign: 'right' }}
                    />
                  </ListItem>
                ))}
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText primary="Total" />
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {moneyFormatter.format(
                      order.pedido_item?.reduce(
                        (a, b) => Number(a) + Number(b.valor_total),
                        0,
                      ) ?? 0,
                    )}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default OrderDetailsPage
