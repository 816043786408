import { zodResolver } from '@hookform/resolvers/zod'
import { Search, SearchOutlined } from '@mui/icons-material'
import { Container, Grid, InputAdornment, TextField } from '@mui/material'
import Paper from '@mui/material/Paper'
import { DataGrid, type GridColDef } from '@mui/x-data-grid'
import { Button } from 'components/button'
import { Typography } from 'components/Typography'
import type { UserDTO } from 'dto/user-dto'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import clientAdmin from 'services/api/v1/admin/client'
import { datagrid } from 'utils'
import { capitalize } from 'utils/formatter'
import { z } from 'zod'

const schema = z.object({
  query: z.string().min(1, 'Informe o parâmetro para a pesquisa'),
})

type SchemaType = z.infer<typeof schema>

export default function SearchUser() {
  const [users, setUsers] = useState<UserDTO[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })

  const onSubmit = useCallback(
    async ({ query }: SchemaType) => {
      try {
        if (isSubmitting) return
        setIsLoading(true)
        const {
          data: { users },
        } = await clientAdmin.get(`users/search?query=${query}`)
        setUsers(users)
      } catch (error) {
        alert(error)
      } finally {
        setIsLoading(false)
      }
    },
    [isSubmitting],
  )

  const columns: GridColDef<UserDTO>[] = [
    {
      field: 'id',
      headerName: '#',
      renderCell: ({ row }) => (
        <Link to={`/admin/usuarios/${row.id}`}>{row.id}</Link>
      ),
      width: 100,
    },
    {
      field: 'fullName',
      headerName: 'Nome',
      renderCell: ({ value }) => capitalize(value),
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 150,
    },
  ]

  return (
    <Container maxWidth="lg" component={Paper} sx={{ p: 2, m: 2 }}>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        mb={2}
      >
        <Grid item xs={12}>
          <Typography variant="h4">Pesquisar usuários</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            label="Pesquisar por nome, cpf, telefone, e-mail, código do pedido ou id do ingresso"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            size="small"
            {...register('query')}
            error={!!errors.query}
            helperText={errors.query?.message}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            isLoading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            startIcon={<SearchOutlined />}
          >
            Pesquisar
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          rows={users}
          columns={columns}
          autoHeight
          localeText={datagrid.localizedTextsMap}
        />
      </Grid>
    </Container>
  )
}
