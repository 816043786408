import { Grid, Paper, Skeleton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'

export function OrdersUnderReview() {
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<IPedido[]>([])

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.admin.orderService.underReview()
      setOrders(data.orders)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <PageLoading />

  const columns: GridColDef<IPedido>[] = [
    {
      field: 'codigo',
      headerName: 'Pedido',
      width: 150,
      renderCell: (cell) => (
        <Link to={`/admin/order-details/${cell.value}`}>{cell.value}</Link>
      ),
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 100,
      renderCell: (cell) => dayjs(cell.value).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'valor_pedido',
      headerName: 'Valor pedido',
      width: 100,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'usuario.id',
      headerName: 'Usuário',
      width: 300,
      renderCell: (cell) => (
        <Link to={`/admin/usuarios/${cell.row.usuario.id}`} target="_blank">
          `${cell.row.usuario.nome} ${cell.row.usuario.sobrenome}`,
        </Link>
      ),
    },
    {
      field: 'usuario.email',
      headerName: 'Usuário',
      width: 300,
      renderCell: (cell) => cell.row.usuario.email,
    },
    {
      field: 'evento.titulo',
      headerName: 'Evento',
      flex: 1,
      renderCell: ({ row }) => row.evento.titulo,
    },
  ]

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Pedidos em análise financeira</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={orders}
          columns={columns}
          autoHeight
          getRowId={(row) => row.codigo}
        />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
