import Close from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  alpha,
  Box,
  Container,
  IconButton,
  InputBase,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { EventCard, Loading } from 'components'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { IPromoter } from 'types/promoter'
import { functionWithLoading } from 'utils'
import EventPage from './EventPage'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
}))

type ProducerEventsProps = {
  producerId: number
}

const ProducerEvents = ({ producerId }: ProducerEventsProps) => {
  const [producer, setProducer] = React.useState<IPromoter>()
  const [events, setEvents] = React.useState<IEvento[]>([])
  const [pastEvents, setPastEvents] = React.useState<IEvento[]>([])
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [loadingEvents, setLoadingEvents] = React.useState(true)

  React.useEffect(() => {
    functionWithLoading(
      apiV1.eventService
        .getProducerEvents(producerId.toString())
        .then(({ data }) => {
          setProducer(data.producer)
          setEvents(data.events)
          setPastEvents(data.pastEvents)
        }),
      setLoadingEvents,
    )
  }, [producerId])

  if (loadingEvents || !producer) return <Loading />

  const filtredEvents =
    searchQuery.length > 0
      ? events.filter(
          (x) =>
            x.titulo
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.local_evento
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.id.toString().includes(searchQuery),
        )
      : events

  return (
    <>
      <Helmet>
        <title>{producer.razao_social} - Zé do Ingresso</title>
      </Helmet>

      <Grid container spacing={2} bgcolor="grey.300">
        {/* <Grid item xs={12} sm={2}>
          <Stack
            alignItems="center"
            sx={(theme) => ({
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
                height: '100%',
                p: 2,
              },
            })}
          >
            <Avatar
              alt={producer.razao_social}
              src={constants.URL_FTP + producer.imagem}
              sx={{ bgcolor: 'primary', width: 120, height: 120 }}
              variant="rounded"
            />
          </Stack>
        </Grid> */}
        {/* <Grid item xs={12} sm={7}>
          <Stack
            sx={(theme) => ({
              [theme.breakpoints.only('xs')]: {
                alignItems: 'center',
                pb: 2,
              },
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
                height: '100%',
              },
            })}
          >
            <Typography variant="h6">{producer.razao_social}</Typography>
            <Typography variant="caption" component="p">
              {producer.email}
            </Typography>
            <Typography variant="caption" component="p">
              {producer.telefone}
            </Typography>
            <Typography variant="caption" component="p">
              {producer.endereco}
            </Typography>
          </Stack>
        </Grid> */}
        {events.length > 1 && (
          <Grid item xs={12} sm={3}>
            <Stack
              height="100%"
              justifyContent="flex-end"
              alignItems="baseline"
              pb={2}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Pesquisar eventos…"
                  inputProps={{
                    'aria-label': 'search',
                  }}
                  endAdornment={
                    searchQuery && (
                      <IconButton
                        aria-label="close search"
                        component="span"
                        onClick={() => setSearchQuery('')}
                      >
                        <Close />
                      </IconButton>
                    )
                  }
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </Search>
            </Stack>
          </Grid>
        )}
      </Grid>

      {events.length === 1 ? (
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <EventPage id={events[0].id} />
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="lg">
          <Typography variant="h6" gutterBottom>
            Próximos eventos
          </Typography>
          <Grid container spacing={2} mt={1}>
            {filtredEvents.map((event) => (
              <Grid item key={'event_' + event.id} xs={12} sm={6} md={4} lg={3}>
                <Link
                  component={RouterLink}
                  to={`/evento/${event.id}/${event.id_url}`}
                  underline="none"
                >
                  <EventCard event={event} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
      {pastEvents.length > 1 && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Container maxWidth="lg">
              <Typography variant="h6">Eventos anteriores</Typography>
              <Carousel
                responsive={responsive}
                showDots
                autoPlay
                autoPlaySpeed={3000}
                infinite
              >
                {pastEvents.map((event) => (
                  <Box key={'pastEvent_' + event.id} sx={{ pl: 1 }}>
                    <Link
                      component={RouterLink}
                      to={`/evento/${event.id}/${event.id_url}`}
                      underline="none"
                    >
                      <EventCard event={event} />
                    </Link>
                  </Box>
                ))}
              </Carousel>
            </Container>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ProducerEvents
