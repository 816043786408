import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { useShoppingCart } from 'contexts/shoppingCartContext'
import type { ISetor } from 'types/setor'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type SelectableSectorProps = {
  sector: ISetor
}

export function SelectableSector({ sector }: SelectableSectorProps) {
  const { shoppingCart, addShoppingCartItem, removeShoppingCartItem } =
    useShoppingCart()

  const selectedValues = shoppingCart
    .filter((item) => item.cod_setor === sector.id)
    .map((item) => item.id.toString())

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const id = event.target.value
    const existingItem = sector.ingresso.find((item) => item.id === Number(id))
    if (!existingItem) return

    if (checked) {
      addShoppingCartItem(existingItem)
    } else {
      removeShoppingCartItem(existingItem)
    }
  }

  const existingItemInCart = (id: string) =>
    !!shoppingCart.find((item) => item.id === Number(id))

  const getTitle = (id: string) =>
    sector.ingresso.find((item) => item.id === Number(id))?.titulo ?? ''

  return (
    <FormControl fullWidth>
      <InputLabel id={`${sector.id}-multiple-select-items`}>
        Selecione
      </InputLabel>
      <Select
        labelId={`${sector.id}-multiple-select-items`}
        id={`${sector.id}-multiple-select-items`}
        multiple
        value={selectedValues}
        input={<OutlinedInput label="Selecione" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={getTitle(value)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {sector.ingresso.map((batch) => (
          <MenuItem
            key={batch.id}
            value={batch.id.toString()}
            disabled={batch.quantidade_disponivel === 0}
          >
            <ListItemText primary={batch.titulo} />

            {batch.quantidade_disponivel > 0 && (
              <Checkbox
                checked={existingItemInCart(batch.id.toString())}
                value={batch.id}
                onChange={handleChangeCheckbox}
              />
            )}

            {batch.quantidade_disponivel === 0 && (
              <Chip label="esgotado" color="error" />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
