import moment from 'moment'

const moneyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
})

const capitalize = (text: string) => {
  if (!text || text.length < 2) return ''
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const normalizeDateTime = (dateTimeRaw: string) => {
  try {
    return moment(dateTimeRaw).add(3, 'hours')
  } catch {
    return moment(dateTimeRaw)
  }
}

export { moneyFormatter, capitalize, normalizeDateTime }
