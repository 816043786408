import clientAdmin from 'services/api/v1/admin/client'

type Props = {
  userId: string
  data: {
    needsFinancesReview: boolean
    email: string
    cpf: string
    firstName: string
    lastName: string
    phone: string
    birthDate: string
    gender: string
    cityId: string
    isBlocked: boolean
    isEmailVerified: boolean
    isPhoneVerified: boolean
  }
}

export async function updateUserHttp({ userId, data }: Props) {
  return await clientAdmin.patch(`/users/${userId}`, data)
}
