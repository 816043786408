import CancelIcon from '@mui/icons-material/Cancel'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AxiosError } from 'axios'
import React from 'react'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'

type CancelOrderButtonProps = {
  orderId: string
}

const CancelOrderButton = ({ orderId }: CancelOrderButtonProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancelOrder = React.useCallback(() => {
    handleClose()
    functionWithLoading(
      apiV1.orderService
        .cancelOrder(orderId)
        .then(() => {
          setIsSuccess(true)
          setMessage(
            'Foi enviada uma solicitação de cancelamento do pedido, aguarde alguns minutos por favor!',
          )
        })
        .catch((err: AxiosError) => {
          const defaultMessage =
            'Ocorreu um erro ao cancelar o pedido, tente novamente ou entre em contato com o suporte!'
          const errorMessage = getErrorMessage(err, defaultMessage)
          console.error(err.response?.data)
          setMessage(errorMessage)
        }),
      setLoading,
    )
  }, [orderId])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 2,
        gap: 2,
      }}
    >
      {!!message && (
        <Alert
          severity={isSuccess ? 'success' : 'error'}
          onClose={() => setMessage('')}
        >
          {message}
        </Alert>
      )}
      {!isSuccess && (
        <LoadingButton
          loadingPosition="start"
          startIcon={<CancelIcon />}
          loading={loading}
          disabled={loading || isSuccess}
          onClick={handleClickOpen}
          variant="outlined"
          color="error"
        >
          Cancelar pedido
        </LoadingButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Cancelar o pedido?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O pedido será estornado de acordo com os termos de uso da plataforma
            e o meio de pagamento utilizado na compra.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOrder} variant="contained">
            Sim
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="error"
            autoFocus
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default CancelOrderButton
