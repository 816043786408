import { zodResolver } from '@hookform/resolvers/zod'
import { Close } from '@mui/icons-material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Loading } from 'components'
import dayjs from 'dayjs'
import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { ICupomDesconto } from 'types/cupom_desconto'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const schema = z.object({
  codigo: z
    .string({ required_error: 'Preencha o cupom!' })
    .max(20, 'O cupom pode ter no máximo 20 caracteres'),
  descricao: z.string(),
  cod_tipo: z.number({ required_error: 'Informe o tipo do cupom!' }),
  quantidade: z.coerce.number({
    required_error: 'Informe a quantidade de cupons disponíveis!',
  }),
  validade: z.coerce.date({ required_error: 'Informe a validade do cupom!' }),
  valor: z.coerce.number({ required_error: 'Informe o valor do cupom' }),
  valor_pedido_minimo: z.coerce.number().min(0).optional(),
  valor_maximo_desconto: z.coerce.number().min(0).optional(),
})

type DiscountCouponFormType = z.infer<typeof schema>

type DiscountCouponFormProps = {
  discountCouponId: number
  callback: () => void
  isAdmin?: boolean
}

export function DiscountCouponForm({
  discountCouponId,
  callback,
  isAdmin = false,
}: DiscountCouponFormProps) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')

  const params = useParams()
  const eventId = Number(params.eventId)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<DiscountCouponFormType>({
    resolver: zodResolver(schema),
    defaultValues: { descricao: '' },
  })

  const load = useCallback(async () => {
    try {
      setIsLoading(true)
      if (discountCouponId !== 0) {
        const { data } = isAdmin
          ? await apiV1.admin.discountCouponService.get(
              eventId,
              discountCouponId,
            )
          : await apiV1.producer.discountCouponService.get(
              eventId,
              discountCouponId,
            )
        reset(data.discountCoupon)
      }
    } catch (error) {
      setError(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [discountCouponId, eventId, isAdmin, reset])

  useEffect(() => {
    load()
  }, [load])

  const onSubmit = async (discountCoupon: DiscountCouponFormType) => {
    try {
      setIsLoading(true)

      if (isSubmitting) {
        return
      }

      if (discountCouponId === 0) {
        const { data } = isAdmin
          ? await apiV1.admin.discountCouponService.create(
              eventId,
              discountCoupon as unknown as ICupomDesconto,
            )
          : await apiV1.producer.discountCouponService.create(
              eventId,
              discountCoupon as unknown as ICupomDesconto,
            )
        setSuccess(
          `Cupom ${data.discountCoupon.codigo} cadastrado com sucesso!`,
        )
      } else {
        const { data } = isAdmin
          ? await apiV1.admin.discountCouponService.update(
              eventId,
              discountCoupon as unknown as ICupomDesconto,
            )
          : await apiV1.producer.discountCouponService.update(
              eventId,
              discountCoupon as unknown as ICupomDesconto,
            )
        setSuccess(
          `Cupom ${data.discountCoupon.codigo} atualizado com sucesso!`,
        )
      }
      callback()
    } catch (error) {
      setError(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  const title =
    discountCouponId === 0
      ? 'Cadastrar cupom de desconto'
      : 'Atualizar cupom de desconto'

  if (isLoading) return <Loading />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton
            aria-label="delete"
            size="large"
            color="primary"
            onClick={callback}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Stack>
        {!!success && (
          <Alert severity="success" onClose={() => setSuccess('')}>
            {success}
          </Alert>
        )}
        {!!error && (
          <Alert severity="error" onClose={() => setError('')}>
            {error}
          </Alert>
        )}
      </Grid>

      <Grid item xs={6} sm={6}>
        <TextField
          variant="outlined"
          label="Código do cupom"
          error={!!errors.codigo}
          helperText={errors.codigo?.message}
          {...register('codigo')}
          fullWidth
          required
          disabled={!!discountCouponId}
        />
      </Grid>

      <Grid item xs={6} sm={6}>
        <Controller
          control={control}
          name="cod_tipo"
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="cod-tipo-select-label">Tipo *</InputLabel>
              <Select
                labelId="uf-select-label"
                id="cod-tipo-select"
                label="Tipo"
                {...field}
                required
                disabled={!!discountCouponId}
              >
                <MenuItem value={1}>Fixo</MenuItem>
                <MenuItem value={2}>Percentual</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Descrição"
          error={!!errors.descricao}
          helperText={errors.descricao?.message}
          {...register('descricao')}
          fullWidth
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <TextField
          variant="outlined"
          label="Valor"
          error={!!errors.valor}
          helperText={errors.valor?.message}
          fullWidth
          {...register('valor', {
            valueAsNumber: true,
            validate: (value) => value > 0,
          })}
          type="number"
          required
          disabled={!!discountCouponId}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <TextField
          variant="outlined"
          label="Quantidade"
          error={!!errors.quantidade}
          helperText={errors.quantidade?.message}
          {...register('quantidade')}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name="validade"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <DateTimePicker
                label="Validade"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.validade,
                    helperText: errors.validade?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <TextField
          variant="outlined"
          label="Valor mínimo do pedido"
          error={!!errors.valor}
          helperText={errors.valor?.message}
          fullWidth
          {...register('valor_pedido_minimo')}
          type="number"
          required
          disabled={!!discountCouponId}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <TextField
          variant="outlined"
          label="Valor máximo de desconto"
          error={!!errors.valor}
          helperText={errors.valor?.message}
          fullWidth
          {...register('valor_maximo_desconto')}
          type="number"
          required
          disabled={!!discountCouponId}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        mb={2}
      >
        <Button variant="outlined" color="error" onClick={callback}>
          Fechar
        </Button>
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveOutlinedIcon />}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
