import { zodResolver } from '@hookform/resolvers/zod'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material'
import { Button } from 'components/button'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const schema = z.object({
  eventId: z.coerce.number().min(1, 'Informe o código do evento'),
  batchId: z.coerce.number().min(1, 'Informe o código do lote para pesquisa'),
  newBatchId: z.coerce.number().min(1, 'Informe o código do lote adicionar'),
})
type SchemaType = z.infer<typeof schema>

export function AddBatchInOrders() {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({ resolver: zodResolver(schema) })

  const onSubmit = useCallback(
    async ({ eventId, batchId, newBatchId }: SchemaType) => {
      try {
        console.log({ eventId, batchId, newBatchId })
        if (isSubmitting) return
        setIsLoading(true)
        await clientAdmin.post(`events/${eventId}/add-batch-in-orders`, {
          batchId,
          newBatchId,
        })
      } catch (error) {
        setErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [isSubmitting],
  )

  console.log(errors)

  return (
    <Card>
      <CardHeader
        title="Adicionar lote no pedido"
        subheader="Adiciona um lote em todos os pedidos com o lote pesquisado de um evento"
      />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            placeholder="Código do evento"
            variant="outlined"
            {...register('eventId')}
            error={!!errors.eventId}
            helperText={errors.eventId?.message}
            required
          />
          <TextField
            placeholder="Codigo do lote para pesquisa"
            variant="outlined"
            {...register('batchId')}
            error={!!errors.batchId}
            helperText={errors.batchId?.message}
            required
          />
          <TextField
            placeholder="Codigo do lote para adicionar"
            variant="outlined"
            {...register('newBatchId')}
            error={!!errors.newBatchId}
            helperText={errors.newBatchId?.message}
            required
          />
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          >
            Adicionar lote no pedido
          </Button>
        </Stack>
      </CardContent>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Alert
          onClose={() => setErrorMessage('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  )
}
