import { zodResolver } from '@hookform/resolvers/zod'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Autocomplete,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TableHead,
  TextField,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Loading } from 'components'
import { Button } from 'components/button'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { getUserHttp } from 'http/get-user'
import { updateUserHttp } from 'http/update-user'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IUsuario } from 'types/usuario'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

const userSchema = z.object({
  id: z.coerce.string(),
  nome: z.string(),
  sobrenome: z.string(),
  cpf: z.string(),
  email: z.string(),
  ddd: z.string(),
  telefone: z.string(),
  data_nascimento: z.coerce.date(),
  sexo: z.string(),
  cod_brasil_cidade: z.coerce.number(),
  telefone_confirmado: z.boolean(),
  email_confirmado: z.boolean(),
  bloqueado: z.boolean(),
  analisar_financeiro: z.boolean(),
})

type FormData = z.infer<typeof userSchema>

export function UserDetails() {
  const [user, setUser] = useState<IUsuario>()
  const [cities, setCities] = useState<{ id: number; label: string }[]>([])
  const [ufs, setUfs] = useState<string[]>([])
  const [selectedUF, setSelectedUF] = useState<string>('')

  const { addErrorMessage, addMessage } = useAdmin()
  const { userId } = useParams()

  const queryClient = useQueryClient()
  const getUser = useQuery({
    queryKey: ['admin_user'],
    queryFn: async () => {
      const response = await getUserHttp(userId ?? '')
      return response.data
    },
  })

  const getUfs = useQuery({
    queryKey: ['ufs'],
    queryFn: () => apiV1.cityService.getUFs(),
  })

  const getCitiesMutation = useMutation({
    mutationFn: apiV1.cityService.getCities,
    onSuccess(data) {
      setCities(
        data.data.map((x) => ({ id: x.codigo_cidade, label: x.cidade })),
      )
    },
  })

  const updateUserMutation = useMutation({
    mutationFn: updateUserHttp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin_user'] })
      addMessage({
        message: 'Usuário atualizado com sucesso',
        type: 'SUCCESS',
      })
    },
    onError(error) {
      addErrorMessage(getErrorMessage(error))
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(userSchema),
    shouldFocusError: true,
  })

  function handleOnSubmit(data: FormData) {
    if (isSubmitting || !user) return
    updateUserMutation.mutate({
      userId: user.id.toString(),
      data: {
        needsFinancesReview: data.analisar_financeiro,
        email: data.email,
        cpf: data.cpf,
        firstName: data.nome,
        lastName: data.sobrenome,
        phone: [data.ddd, data.telefone].join(''),
        birthDate: data.data_nascimento.toISOString().split('T')[0],
        gender: data.sexo,
        cityId: data.cod_brasil_cidade.toString(),
        isBlocked: data.bloqueado,
        isEmailVerified: data.email_confirmado,
        isPhoneVerified: data.telefone_confirmado,
      },
    })
  }

  useEffect(() => {
    if (getUser.isSuccess) {
      setUser(getUser.data.user)
      if (getUser.data.user.brasil_cidade?.uf)
        setSelectedUF(getUser.data.user.brasil_cidade.uf)
      reset(getUser.data.user)
    }
  }, [getUser.data, getUser.isSuccess, reset])

  useEffect(() => {
    if (getUfs.isSuccess) {
      setUfs(getUfs.data.data.map((uf) => uf.uf))
    }
  }, [getUfs.data, getUfs.isSuccess])

  useEffect(() => {
    if (selectedUF) {
      getCitiesMutation.mutate(selectedUF)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUF])

  if (getUser.isLoading)
    return (
      <Loading>
        <Skeleton variant="rectangular" height={400} />
      </Loading>
    )

  if (!user) return null

  return (
    <Container maxWidth="xl" component={Paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Detalhes do usuário</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Nome"
            variant="outlined"
            error={!!errors.nome}
            helperText={errors.nome?.message}
            {...register('nome')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Sobrenome"
            variant="outlined"
            error={!!errors.sobrenome}
            helperText={errors.sobrenome?.message}
            {...register('sobrenome')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="CPF"
            variant="outlined"
            error={!!errors.cpf}
            helperText={errors.cpf?.message}
            {...register('cpf')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="E-mail"
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="DDD"
            variant="outlined"
            error={!!errors.ddd}
            helperText={errors.ddd?.message}
            {...register('ddd')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Telefone"
            variant="outlined"
            error={!!errors.telefone}
            helperText={errors.telefone?.message}
            {...register('telefone')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="data_nascimento"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Data de nascimento"
                value={dayjs(field.value).toDate()}
                onChange={(value) => field.onChange(value)}
                slotProps={{
                  textField: { fullWidth: true, size: 'small' },
                }}
              />
            )}
          />
          {errors.data_nascimento && (
            <FormHelperText error>
              {errors.data_nascimento.message}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="sexo"
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>Sexo</InputLabel>
                <Select
                  label="Sexo"
                  defaultValue="Prefiro não informar"
                  value={field.value || 'Prefiro não informar'}
                  onChange={field.onChange}
                  fullWidth
                  size="small"
                >
                  <MenuItem value={'Masculino'}>Masculino</MenuItem>
                  <MenuItem value={'Feminino'}>Feminino</MenuItem>
                  <MenuItem value={'Prefiro não informar'}>
                    Prefiro não informar
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          {getUfs.isLoading ? (
            <Skeleton />
          ) : (
            <FormControl fullWidth>
              <InputLabel>UF</InputLabel>
              <Select
                value={selectedUF}
                label="UF"
                onChange={(event) => {
                  setSelectedUF(event.target.value)
                }}
                size="small"
              >
                {ufs.map((uf) => (
                  <MenuItem key={uf} value={uf}>
                    {uf}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} sm={9} md={10}>
          <Controller
            name="cod_brasil_cidade"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={cities.map((x) => x.id)}
                getOptionLabel={(item) =>
                  cities.find((x) => Number(x.id) === Number(item))?.label ??
                  'Selecione'
                }
                value={field.value > 0 ? field.value : null}
                loading={getCitiesMutation.isPending}
                loadingText="Carregando..."
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    error={!!errors.cod_brasil_cidade}
                    helperText={errors.cod_brasil_cidade?.message}
                    disabled={!selectedUF}
                    size="small"
                  />
                )}
                onChange={(event, values) => field.onChange(values)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="telefone_confirmado"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={!!field.value} />}
                label="Telefone confirmado"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="email_confirmado"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={!!field.value} />}
                label="E-mail confirmado"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="bloqueado"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={!!field.value} />}
                label="Bloqueado"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="analisar_financeiro"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={!!field.value} />}
                label="Analisar financeiro"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(handleOnSubmit)}
            loading={updateUserMutation.isPending}
          >
            Salvar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Notas:</Typography>
          {user.usuario_nota?.map((nota) => (
            <Typography key={nota.id}>
              {dayjs(nota.datacadastro).format('DD/MM/YY HH:mm')} - {nota.tipo}{' '}
              - {nota.texto}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Evento</TableCell>
                  <TableCell>Situação</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.pedido?.map((order) => (
                  <TableRow
                    key={order.codigo}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/admin/order-details/${order.codigo}`}
                        target="_blank"
                      >
                        {order.codigo}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {dayjs(order.data)
                        .add(3, 'hours')
                        .format('DD/MM/YY HH:mm')}
                    </TableCell>
                    <TableCell>{order.evento.titulo}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>
                      {moneyFormatter.format(order.valor_pedido)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  )
}
